import Splide from '@splidejs/splide';

document.querySelectorAll('.cm-slider .splide').forEach(slider => {
  new Splide(slider, {
    perPage: 3,
    gap: 'var(--gutter)',
    breakpoints: {
      1280: {
        perPage: 2
      },
      768: {
        perPage: 1
      }
    }
  }).mount();
})